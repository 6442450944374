import React from 'react'
import { css } from '@emotion/react'
import Logo from '../media/Logo'
import { DestinationSearch, SearchResult } from './destinations/DestinationSearch'
import { ConfigContextConsumer } from '../config/ConfigContext'
import { useQueryParam, NumberParam, StringParam } from 'use-query-params'
import { Button } from 'antd'
import usePickupDestination from './destinations/usePickupDestination'
import { DestinationsContext } from './destinations/Destinations'
import { isMobile } from 'react-device-detect'

const styles = {
  chat: css`
    position: absolute;
    bottom: 30px;
    width: 600px;
    margin-left: calc(50% - 300px); //Half of $gsi-chat-width less than midpoint to center element.

    // @extend %gsi-chat;
    box-shadow: 0px -2px 15px $SF-med-dark-gray;

    //On home page, we float the chat so it has rounded corners on the bottom
    border-radius: 7px;

    @media (max-width: 768px) {
      width: 90%;
      border-radius: 0;
      margin-left: 5%;
    }

    @media (max-width: 480px) {
      width: 94%;
      border-radius: 0;
      margin-left: 3%;
    }
  `,
  head: css`
    position: relative;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.85);
    //Needed for box shadow to show on top of messages
    z-index: 3;

    text-align: center;
    padding: 10px 0;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    box-shadow: 0 4px 4px rgba(182, 182, 182, 0.75);

    height: 64px; //Prevents visible resizing while logo loads.

    display: flex;
    justify-content: center;
  `,
  output: css`
    position: relative;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    border-bottom: none;
    padding: 5px 15px 25px 10px;
    display: flex;
    justify-content: left;
  `,
  input: css`
    position: relative;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.85);
    z-index: 3;
    bottom: 0;
    padding: 5px 7px;
    height: 48px;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    display: flex;
    justify-content: center;
  `,
  inputMobile: css`
    position: fixed;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.85);
    z-index: 3;
    bottom: 0;
    padding: 5px 7px;
    height: 48px;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    display: flex;
    justify-content: center;
  `,
  msg: css`
    border-radius: 4px;
    word-wrap: break-word;
    color: black;

    max-width: 80%;
    min-width: 30%;

    box-shadow: 0 0 10px $SF-med-dark-gray;

    a {
      text-decoration: underline;
    }

    color: black;
    background-color: #ccccff;

    font-size: 17px;
    padding: 8px 15px;
    margin-top: 12px;

    @media (max-width: 768px) {
      padding: 5px 8px;
      font-size: 15px;
    }
  `,
  logo: css`
    img {
      width: auto;
    }
  `
}

// TODO: Copied form stickyfooter
const baseCta = css`
  background-color: #ec781d; //orange
  font-size: 18px;
  font-weight: 500;
  padding: 3px;
  height: 38px;
  border: none;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;

  &:hover {
    background-color: #ff9933; //light orange
  }

  &:active,
  &:focus {
    background-color: #ec781d; //orange
  }
`

// TODO: Copied form stickyfooter
const cta = css`
  width: 160px;

  @media (max-width: 768px) {
    display: none;
  }
`

const ctaMobile = css`
  width: 80px;
  display: none;

  @media (max-width: 768px) {
    display: inline;
  }
`

const GetStartedChat = ({
  baseAppUrl,
  destinationsState
}: {
  baseAppUrl: any
  destinationsState: any
}) => {
  const [campaign] = useQueryParam('campaign', NumberParam)
  const [vehicle] = useQueryParam('vehicle', NumberParam)
  const [utm_source] = useQueryParam('utm_source', StringParam)

  const defaultDest = usePickupDestination(destinationsState)
  const params = new URLSearchParams()
  if (campaign && !isNaN(campaign)) params.append('campaign', campaign.toString())
  if (vehicle && !isNaN(vehicle)) params.append('vehicle', vehicle.toString())
  if (utm_source) params.append('utm_source', utm_source);

  const createTrip = () => {
    window.location = `${baseAppUrl}/createtrip?${params.toString()}`
  }

  const onClick = () => {
    if (defaultDest) params.append('dest', defaultDest.value)
    createTrip()
  }

  const onChange = (dest: SearchResult) => {
    params.append('dest', dest.value)
    createTrip()
  }

  return (
    <div css={styles.chat}>
      <div css={styles.head}>
        <Logo />
      </div>
      <div css={styles.output}>
        <span css={styles.msg}>Where should we pick you up?</span>
      </div>
      <div css={styles.input}>
        <DestinationSearch onChange={onChange} />
        <Button type="primary" css={[baseCta, cta]} onClick={onClick}>
          Get Started
        </Button>
        <Button type="primary" css={[baseCta, ctaMobile]} onClick={onClick}>
          Start
        </Button>
      </div>
    </div>
  )
}

const GetStartedChatWrapped = (props: any) => {
  return (
    <DestinationsContext.Consumer>
      {value => (
        <ConfigContextConsumer>
          {({ baseAppUrl }) => (
            <GetStartedChat baseAppUrl={baseAppUrl} {...props} destinationsState={value} />
          )}
        </ConfigContextConsumer>
      )}
    </DestinationsContext.Consumer>
  )
}

export default GetStartedChatWrapped
