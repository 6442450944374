import { useEffect, useState } from 'react'
import { Destination } from './DestinationSearch'
import { useLocation } from '@reach/router'

const usePickupDestination = (destinationState: any): Destination | undefined => {
  const [defaultDestination, setDefaultDestination] = useState<Destination>()
  const location = useLocation()

  useEffect(() => {
    console.log("Destination State and location in usePickupDestination: ", destinationState, location);
    
    if (destinationState && destinationState.store && location) {
      let defaultDest: Destination

      if (location && location.pathname && location.pathname.startsWith('/h/')) {
        const tokens = location.pathname.split('/').filter(Boolean)
        const pickup = tokens[1]
        // CodeMap is defined with store, why its excluded from if check above
        const pickupDest = pickup ? destinationState.codeMap[pickup] : undefined
        if (pickupDest) {
          defaultDest = {
            label: pickupDest.label,
            value: pickupDest.id,
            suffix: pickupDest.suffix,
            name: pickupDest.name
          }

          setDefaultDestination(defaultDest)
        }
      }
    }
  }, [destinationState, location])

  return defaultDestination
}

export default usePickupDestination
